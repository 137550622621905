.featured {
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-md);
  gap: 1rem;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .featuredChart {
      width: 100px;
      height: 100px;
    }

    .amount {
      font-size: 30px;
    }

    .desc {
      font-weight: 300;
      font-size: 12px;
      color: gray;
      text-align: center;
    }

    .details {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: .5rem;
    }

    .summary {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        text-align: center;

        .itemTitle {
          font-size: 14px;
          color: gray;
        }

        .itemResult {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-size: 14px;

          &.positive {
            color: green;
          }
          &.zero {
            color: var(--blue-50);
          }
          &.negative {
            color: var(--red-80);
          }
        }
      }
    }
  }
}
