.checklist-filter-content {
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  gap: 1rem;
}

.checklist-drawer {
  display: grid;
  grid-template-rows: auto 5rem;
  align-content: space-between;
  height: 100%;
}

.checklist-drawer-container {
  display: flex;
  width: 42rem;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 2.5rem;
  gap: 1rem;
}

.checklist-details-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.checklist-details-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.checklist-drawer-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

//listOfChecklists
.list-checklist-container {
  margin: 2rem 0rem 1rem 0rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: self-start;

  .list-row {
    display: flex !important;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    align-items: center;
    .Input {
      width: 100%;
    }
  }

  .button-left {
    display: flex;
    justify-content: right;
    align-items: center;
  }
}
