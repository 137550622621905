.gueno-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;

  img {
    width: 6rem;
  }
}

.gueno-logo-small {
  display: flex;
  align-items: center;
  justify-content: center;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 0.5rem;

  img {
    width: 2.75rem;
  }
}

.swipeable {
  display: flex;
  background-color: inherit;
  height: 100%;

  .swipeable-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
  }

  .swipeable-bottom {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    align-self: flex-end;
    position: relative;
  }

  .item-button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .item-icon {
    display: flex;
    min-width: 0;
    justify-content: center;
  }

  .item-icon-right {
    min-width: 0;
    margin: auto;
    justify-content: center;
  }
}
