.threshold-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.threshold-inputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.button-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
