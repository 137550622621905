.formContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: 900px;
  gap: 8px;
}

.selfieContainer {
  width: 45%;
  align-self: center;
}

.documentContainer {
  display: flex;
  gap: 24px;
  width: 100%;
  justify-content: center;
}

.actionButtons {
  display: flex;
  align-self: end;
}