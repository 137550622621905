.modal-box-lists {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: auto;
  max-height: 70vh;
  padding-right: 0.5rem;
}

.modal-lists-top {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.modal-lists.edition {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.lists-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lists-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}

.lists-box-divider {
  width: 100%;
  grid-column: 1 / 3;
}

.lists-box-divider2 {
  width: 100%;
  grid-column: 1 / 5;
}

.lists-box-users {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.lists-box-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}

.lists-box-users-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr auto auto;
  align-items: end;
  gap: 1rem;
  margin-bottom: 1rem;
}
