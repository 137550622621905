$colors: (
  'blue-20': hsl(197, 31%, 89%),
  'blue-30': hsl(196, 93%, 89%),
  'blue-40': hsl(197, 38%, 48%, 0.4),
  'blue-50': hsl(195, 95%, 56%),
  'blue-60': hsl(197, 38%, 48%),
  'blue-70': hsl(209, 52%, 51%),
  'blue-75': hsl(219, 95%, 56%),
  'blue-80': hsl(221, 54%, 22%),
  'grey-20': hsl(210, 12%, 94%),
  'grey-25': hsl(240, 2%, 91%),
  'grey-30': hsl(240, 30%, 86%),
  'grey-35': hsl(210, 17%, 98%),
  'grey-40': hsl(0, 0%, 96%),
  'grey-45': hsl(233, 7%, 75%),
  'grey-50': hsl(0, 0%, 65%),
  'grey-60': hsl(236, 7%, 61%),
  'grey-70': hsl(235, 10%, 43%),
  'grey-75': hsl(234, 10%, 35%),
  'grey-80': hsl(236, 10%, 32%),
  'grey-90': hsl(231, 9%, 15%),
  'green-40': hsl(96, 76%, 52%),
  'green-50': hsl(139, 89%, 46%),
  //	#0dde4f
  'error': hsl(9, 100%, 56%),
  'orange-40': hsl(35, 100%, 50%),
  'red-80': hsl(359, 62%, 40%),
  'black': hsl(203, 5%, 5%),
  'white': hsl(0, 0%, 98%),
  'bright-white': hsl(0, 0%, 100%),
  'suspend': hsl(237, 100%, 74%),
  'block': hsl(9, 100%, 56%),
  'flag': hsl(40, 98%, 58%),
  'processing': hsl(51, 100%, 77%),
  'successful': hsl(96, 80%, 48%),
  'sent': hsl(180, 100%, 40%),
  'suspended': hsl(230, 100%, 73%),
  'refunded': hsl(303, 53.37%, 66.21%),
  'declined': hsl(351, 100%, 63%),
  'created': hsl(0, 0%, 92%),
  'open': hsl(219, 13%, 49%),
  'in-progress': hsl(40, 98%, 58%),
  'closed': hsl(209, 52%, 51%),
  'alert': hsl(354, 77%, 73%, 0.9),
  'very-high': hsl(359, 62%, 40%),
  'high': hsl(9, 100%, 56%),
  'medium': hsl(40, 97%, 57%),
  'low': hsl(139, 89%, 37%),
  'very-low': hsl(237, 9%, 44%),
  'ach': hsl(192, 64%, 67%),
  'card': hsl(237, 100%, 74%),
  'iban': hsl(50, 75%, 57%),
  'upi': hsl(285, 53%, 56%),
  'generic-bank-account': hsl(140, 58%, 47%),
  'mpesa': hsl(175, 48%, 51%),
  'swift': hsl(237, 47%, 48%),
  'wallet': hsl(0, 46%, 58%),
  'check': hsl(46, 47%, 43%),
  'cbu': hsl(192, 64%, 67%),
  'cvu': hsl(237, 100%, 74%),
  'echeck': hsl(50, 75%, 57%),
  'debin': hsl(285, 53%, 56%),
  'qr-code': hsl(140, 58%, 47%),
  'online-payment': hsl(175, 48%, 51%),
  'ted': hsl(237, 47%, 48%),
  'boleto': hsl(0, 46%, 58%),
  'pix': hsl(46, 47%, 43%),
  'identity': hsl(261, 22%, 54%),
  'document': hsl(240, 38%, 50%),
  'risk-level': hsl(199, 80%, 48%, 0.63),
  'outdated': hsl(4, 64%, 73%, 0.97),
  'next': hsl(199, 80%, 48%, 0.63),
);

:root {
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }
}
