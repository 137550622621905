input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.kyb-detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .kyb-details:hover {
    cursor: pointer;
  }

  .kyb-files-drag {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .kyb-file-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .kyb-file-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .kyb-files {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .kyb-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .kyb-file-description {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .kyb-file-icons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .kyb-subdetail {
    display: flex;
  }

  .kyb-subgrid {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .kyb-titles {
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .kyb-detail-head {
    display: grid;
    grid-template-columns: 82% 18%;
  }

  .kyb-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .kyb-info-section-head {
      display: flex;
      gap: 0.75rem;
      align-items: center;
      justify-content: space-between;

      .kyb-info-section-1 {
        display: flex;
        gap: 0.75rem;
      }
    }

    .kyb-info-section-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
    }

    .kyb-info-section-3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column: 1 / 4;
      gap: 0.5rem;
    }

    .kyb-info-section-4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.5rem;
    }

    .kyb-info-section-span {
      grid-column: 1 / 4;
    }
  }

  .details-sub {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .risk-level {
      display: flex;
      flex-direction: column;

      .risk-level-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
      }
    }

    .status-level {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .status-level-top {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .details-sub2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .risk-level {
      display: flex;
      flex-direction: column;

      .risk-level-top {
        display: flex;
        justify-content: space-between;
      }
    }

    .status-level {
      display: flex;
      flex-direction: column;
      gap: 2.75rem;

      .status-level-top {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .kyb-detail-timeline {
    height: fit-content;

    .timeline-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 1rem;
      max-height: 55rem;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .timeline-card {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .kyb-detail-documents {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .kyb-documents-top {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .kyb-detail-aml {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .kyb-checks-flex {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .kyb-close {
    margin-top: 1rem;

    .kyb-check-status {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }

  .kyb-detail-shareholders {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .kyb-detail-shareholders-top {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

.notifications-row {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.reports-row {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
}

.reports-title-row {
  display: flex;
  justify-content: space-between;
}

.kyb-card {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .card-top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .card-box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .card-titles {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
}

.accordion-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
}

.accordion-status {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.accordion-status-icon {
  width: fit-content;
}

.kyb-country-selector {
  width: 12rem;
}

.accordion-jap {
  border-radius: 0.75rem;
  padding: 1rem 1.5rem;

  .accordion-jap-row {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    justify-content: start;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .accordion-jap-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
  }
}

.forceWidth {
  max-width: 30vw;
}
