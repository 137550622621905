.custom-overlay {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.custom-overlay-icon {
  svg {
    display: flex;
    height: 2.5rem;
    width: 2.5rem;
  }
}
