.container {
  display: flex;
  flex-direction: column;
  padding-right: 8px;
  margin-bottom: 16px;
}

.pageTitle {
  margin-bottom: 16px;
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}


.tableContainer {
  margin-bottom: 16px;
}