.container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}