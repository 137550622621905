.status-icon {
  padding: 0.25rem 0.5rem !important;
  height: 1.5rem !important;
  gap: 0.25rem;

  span {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: normal;
    text-transform: math-auto;
    padding: 0;
  }

  .rl-icon {
    height: 0.35rem;
    width: 0.35rem;
  }
}
