.risk-level-icon {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: flex-start;
  padding: 0.25rem 0.75rem;
  border-radius: 6.25rem;
  width: fit-content;

  p {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: normal;
    text-transform: capitalize;
  }
  
  .rl-icon {
    height: 0.35rem;
    width: 0.35rem;
  }
}
