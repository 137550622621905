.container {
  display: flex;
  flex-direction: column;
  padding-right: 8px;
}

.pageTitle {
  margin-bottom: 16px;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.tableContainer {
  margin-bottom: 16px;
}