.window-input-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .window-input-row {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .window-label {
      display: flex;
      gap: .5rem;
    }
  }
}
