/* .UserDetails_outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
}

.user-detail-section-title {
    text-align: left;
    color: var(--gray-90);
    line-height: 1.25rem;
}

.user-details-subsection-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.user-details-subsection-title {
    font-size: 1.25rem;
    font-weight: 400 !important;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: left;
    color: var(--gray-70);
}

.subsection-item-title {
    line-height: 1rem;
    letter-spacing: 0em;
    text-align: left;
    color: var(--gray-70);
}

.subsection-item-content {
    font-size: .875rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0em;
    text-align: left;
    color: var(--gray-90);
}

.user-details-subsection-personal-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.user-details-subsection-contact-deets {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.user-details-subsection-account-deets {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, fit-content);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.personal-info-subsection-item {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px !important;
    gap: 10px !important;
    border-radius: 16px !important;
}

.forceRowForItem {
    flex-direction: row;
}

.personal-info_createdAt {
    grid-area: 1 / 1 / 2 / 2;
}

.personal-info_userName {
    grid-area: 1 / 2 / 2 / 3;
}

.personal-info_dateOfBirth {
    grid-area: 1 / 3 / 2 / 4;
}

.personal-info_gender {
    grid-area: 2 / 1 / 3 / 2;
}

.personal-info_residence {
    grid-area: 2 / 2 / 3 / 3;
}

.personal-info_nationality {
    grid-area: 2 / 3 / 3 / 4;
}

.personal-info_address {
    grid-area: 3 / 1 / 4 / 4;
}

.account-deets-acquisition-channel { grid-area: 1 / 1 / 2 / 2; }
.account-deets-user-segment { grid-area: 1 / 2 / 2 / 3; }
.account-deets-account-opening { grid-area: 2 / 1 / 3 / 3; }
.account-deets-pep { grid-area: 3 / 1 / 4 / 3; } */
.subsection-item {
  display: flex;
  gap: 1rem;
}

.subsection-item-title {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
