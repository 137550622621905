.diff-flex {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.diff-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.diff-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: calc(100vw - 35rem);
}

.diff-overflow {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: calc(100vh - 12rem);
  overflow: auto;
  padding-right: 0.5rem;
}

.render-data-wrap-text {
  overflow-wrap: break-word;
}
