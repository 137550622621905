.pairRisk {
  display: flex;
  width: 100%;

  .Buttons {
    flex: 1;
    margin: 5px;
    display: flex;

    .ButtonRisk {
      margin-top: 40px;
    }
  }

  .inputs {
    flex: 3;
    display: flex;
    flex-direction: row;

    .InputRisk {
      flex: 1;
      width: 80%;
      margin: 5px;
    }

    .pairInput {
      width: 25%;
    }
  }
}

.lastRowObjects {
  display: flex;
  justify-content: right;
  margin-top: 15px;
}

.cardExp {
  max-width: 100%;
  display: flex;

  span {
    margin-top: 5px;
  }

  .cardExpItem {
    width: 2rem;
    margin: 5px;
  }
}
