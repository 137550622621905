.onboarding-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;

  .onboarding-details-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
  }

  .onboarding-details-bottom {
    display: grid;
    grid-template-columns: 15.5rem auto;
    gap: 1.5rem;
  }

  .onboarding-tablist {
    &.MuiTabs-root .MuiTabs-flexContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  .MuiChip-root {
    justify-content: flex-start;
    width: 100%;
  }

  .MuiButtonBase-root.MuiTab-root {
    width: 100%;
    align-items: flex-start;
  }

  .onboarding-info-summary {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 1rem;
    width: 100%;
  }

  .onboarding-info-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 1rem;
    width: 100%;
  }

  .onboarding-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.5rem;
  }

  .onboarding-info-box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
  }

  .onboarding-analysis {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
  }

  .onboarding-id {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .onboarding-uid {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .onboarding-tooltip {
    display: flex;
    gap: 0.25rem;
  }

  .onboarding-avatar {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }

  .onboarding-summary {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .onboarding-box {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    height: 100%;
    width: 100%;
  }

  .onboarding-box-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }

  .onboarding-payment-details {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    gap: 0.55rem;
  }

  .chip-origin {
    color: var(--calm-dark);
    background-color: var(--calm-light);
    width: fit-content;
  }

  .chip-destination {
    color: var(--alert-dark);
    background-color: var(--alert-light);
    width: fit-content;
  }

  .details-wrapper {
    width: auto !important;
    margin: 1rem;
    background-color: #fff !important;
    padding: 1.5rem;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px !important;
    overflow-x: auto;

    .details-top {
      display: grid;
      grid-template-columns: 1.5rem repeat(4, 1fr);
      align-items: center;
      font-size: 0.85rem;

      .details-back {
        justify-self: self-start;
        padding: 0;

        .icon {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      .details-items {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        justify-content: center;
        align-items: center;

        .action {
          width: 50% !important;

          span {
            font-size: 0.7rem !important;
          }
        }

        p {
          font-weight: 500;
        }

        .tx-id {
          color: var(--blue-50);
          font-size: 0.8rem;
        }

        .tx-time {
          font-weight: 400;
        }
      }
    }
  }

  .details-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;
    width: 100%;

    .details-items {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .title {
      width: fit-content !important;
    }

    .reveal {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }
}
