.onboardingInfoBottom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.onboardingAnalysis {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.onboardingTooltip {
  display: flex;
  gap: 0.25rem;
}

.checkRender {
  display: flex;
  flex-direction: row;

  p {
    margin-left: 10px;
  }
}

.red {
  color: red;
}
.grey {
  color: grey;
}