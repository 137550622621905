.headerTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subHeaderTable {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
}