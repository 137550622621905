.list-strings-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .list-row {
    display: flex;
    gap: 1rem;

    .list-pair-risk {
      display: flex;
      gap: 0.5rem;
    }

    .buttons {
      display: grid;
      gap: 0.5rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .button-left {
    display: flex;
    justify-content: right;
    align-items: center;

    p {
      margin-right: 0.5rem;
    }
  }

  .last-row {
    margin-left: 4rem;
  }
}
