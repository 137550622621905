/* .number-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  .number-input-block {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .number-input-subtitle {
    color: var(--grey-60);
    font-weight: 300;
    font-style: italic;
    font-size: 0.9rem;
  }

  .number-input {
    max-width: 7rem;
  }
}
 */

.number-input-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
