.case-flow {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .case-flow-wrapper {
    width: 100%;
    height: calc(100vh - 20.5rem);
  }

  .case-flow-first-step {
    width: 100%;
    height: calc(100vh - 20.5rem);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.step-input-label {
  display: flex;
  gap: 0.25rem;
}

.step-input {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.75rem !important;
  min-width: 25rem;

  .step-input-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }

  .step-select {
    padding: 0.25rem 0.75rem;
    border-radius: 0.15rem;
  }

  .step-input-box {
    display: flex;
    flex-direction: column;
    gap: 0.15rem;

    label {
      font-size: 0.75rem;
    }
  }

  .step-input-flex {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: 100%;
  }

  .step-input-button {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1.5rem;

    button {
      width: 100%;
    }
  }

  .step-input-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  .step-input-box-filters {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.step-input-filter-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  .MuiButton-root {
    padding: 0rem 0.5rem;
    font-size: 0.8rem;
  }
}

.react-flow__handle {
  width: 14px;
  height: 14px;
  background-color: #7d749b;
}
