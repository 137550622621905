.svg-icon {
  svg {
    display: flex;
    height: 0.875rem;
    width: 0.875rem;
    fill: currentcolor;
  }
}

.svg-icon-sidebar {
  svg {
    display: flex;
    height: 1.25rem;
    width: 1.25rem;
    fill: currentcolor;
  }
}
