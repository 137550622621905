.container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .draggableMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    height: 120px;
    width: 100%;
    border-radius: 1rem;
    line-height: normal;
    cursor: pointer;

    .draggableContainer {
      display: flex;
      flex-flow: column;
      align-items: center;
      gap: 8px;
    }

    .draggableIcon {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath d="M6.90265 22H17.0973C18.0531 22 18.7758 21.7582 19.2655 21.2745C19.7552 20.7966 20 20.0915 20 19.1591V10.5577C20 9.62529 19.7552 8.92016 19.2655 8.44231C18.7758 7.95862 18.0531 7.71678 17.0973 7.71678H14.6903V9.43881H16.9823C17.3894 9.43881 17.7021 9.54662 17.9204 9.76224C18.1445 9.97203 18.2566 10.2896 18.2566 10.715V19.0105C18.2566 19.4359 18.1445 19.7535 17.9204 19.9633C17.7021 20.1789 17.3894 20.2867 16.9823 20.2867H7.00885C6.59587 20.2867 6.28024 20.1789 6.06195 19.9633C5.84956 19.7535 5.74336 19.4359 5.74336 19.0105V10.715C5.74336 10.2896 5.84956 9.97203 6.06195 9.76224C6.28024 9.54662 6.59587 9.43881 7.00885 9.43881H9.31858V7.71678H6.90265C5.9469 7.71678 5.22419 7.95862 4.73451 8.44231C4.24484 8.92016 4 9.62529 4 10.5577V19.1591C4 20.0973 4.24484 20.8054 4.73451 21.2832C5.22419 21.7611 5.9469 22 6.90265 22ZM12 15.0332C12.2242 15.0332 12.4159 14.9545 12.5752 14.7972C12.7404 14.6399 12.823 14.4534 12.823 14.2378V5.43531L12.7522 4.13287L13.3009 4.77098L14.5398 6.08217C14.6873 6.23951 14.8702 6.31818 15.0885 6.31818C15.2891 6.31818 15.4602 6.25408 15.6018 6.12587C15.7493 5.99184 15.823 5.82284 15.823 5.61888C15.823 5.4324 15.7463 5.26049 15.5929 5.10315L12.6195 2.27972C12.5133 2.17483 12.41 2.10198 12.3097 2.06119C12.2094 2.0204 12.1062 2 12 2C11.8938 2 11.7906 2.0204 11.6903 2.06119C11.59 2.10198 11.4838 2.17483 11.3717 2.27972L8.39823 5.10315C8.25074 5.26049 8.17699 5.4324 8.17699 5.61888C8.17699 5.82284 8.24484 5.99184 8.38053 6.12587C8.52212 6.25408 8.69617 6.31818 8.90265 6.31818C9.12684 6.31818 9.30973 6.23951 9.45133 6.08217L10.6903 4.77098L11.2478 4.13287L11.177 5.43531V14.2378C11.177 14.4534 11.2566 14.6399 11.4159 14.7972C11.5811 14.9545 11.7758 15.0332 12 15.0332Z" fill="%2319DB55"%3E%3C/path%3E%3C/svg%3E');
      background-repeat: no-repeat;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  
}