.security-flex {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  align-items: center;
}

.security-flex-label {
  display: flex;
  align-items: center;
}

.security-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  align-items: center;
}

.security-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.security-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: auto !important;
  height: 100%;
  margin: 1rem;
}

.security-back {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.security-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}
