.workflows {
  width: 100%;
  margin-top: 16px;
  cursor: pointer;
}

.options-view {
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  justify-content: center;
  gap: 20 rem;
  max-width: 80%;

  .item {
    display: flex;
    justify-content: center;
  }
}

.img-cursor {
  cursor: pointer;
  width: 95%;
  margin: 10px;
}

.workflow-datatable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 624px;

  .workflow-wrapper {
    background-color: white;
    border-radius: 10px;
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .workflow-head {
      display: grid;
      grid-template-columns: repeat(3, 1fr) auto;
      margin: 10px;
    }

    .options-parent {
      display: flex;
      justify-content: center;
    }
  }

  .workflow-text {
    text-align: center;
  }

  .workflow-subtitle {
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    h4 {
      font-weight: 300;
    }

    hr {
      margin: 20px;
    }

    .workflow-text {
      margin: auto 30px;
    }
  }
}

.button-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  .button-config {
    margin: 10px !important;
  }

  .orange {
    background-color: #ffb041;
  }

  .purple {
    background-color: #a8abfc;
  }

  .blue {
    background-color: #5dd4fc;
  }

  .pink {
    background-color: #f4a4ad;
  }
}

.generate {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.workflows-2 {
  width: 100%;
  margin-top: 16px;
}

.img-wrapper {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 16px;

  img {
    width: 97%;
    margin: 10px;
  }
}

.img-separator {
  margin-bottom: 16px;
  border-radius: 16px;
}

// .react-flows {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   width: 40%;
// }
