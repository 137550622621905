.working-days-container {
  display: grid;
  gap: 0.9rem;
  grid-template-columns: 1fr 0.3fr;
  margin: 2rem 0;
}

.working-days {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.working-days-row {
  display: grid;
  grid-template-columns: 1.5fr repeat(3, 1fr);
  gap: 0.5rem;
  align-items: center;
}

.working-button-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.selected-ranges {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.selected-ranges-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: 3.5rem;
}

.selected-ranges-row {
  display: flex;
  gap: 1rem;
  align-items: center;
}
