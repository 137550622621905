.preview-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;

  .box-division {
    margin-bottom: 0.5rem;
  }

  .preview-title {
    font-size: larger;
    margin-bottom: 0.85rem;
  }

  .preview-block {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: auto;

 

    .preview-subtitle {
      padding: 0.5rem;
      color: var(--green-50);
      font-weight: 500;
    }

    .checklist-actions{
      padding-left: 0px;
      padding-inline-start: 0px;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    } 

    .preview-value {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      //justify-self: start;
      text-align: center;
    }

    .preview-no-data {
      font-size: 0.9rem;
      padding: 0.5rem;
      text-align: center;
      font-style: italic;
    }
  }
}

.render-data-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .render-data-items {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .render-data-items-row {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }
}
