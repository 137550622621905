$colors: (
  //Brand Scale
  'lime-01': #daede0,
  'lime-02': #cffcdd,
  'lime-03': #95fcb6,
  'lime-04': #00c43d,
  'lime-05': #98cba8,

  //Accent Colours
  'lile': #7e65d5,
  'dark-lile': #4e4375,
  'dark-green': #023428,
  'mesh-green': linear-gradient(205deg, #19db55 12.15%, #cffcdd 77.98%),
  //Alert Colours
  'error-dark': #ff2b1b,
  'error-light': #ffd7d4,
  'alert-dark': #ff8a00,
  'alert-light': #f7e89b,
  'success-dark': #00b85f,
  'success-light': #cdf9e7,
  'info-dark': #00bfe9,
  'info-light': #d6f8ff,
  'calm-dark': #7e65d5,
  'calm-light': #e4ddff,

  //Grey Scale - Light Mode
  'gray-00': #fbfffc,
  'gray-10': #f4f8f6,
  'gray-20': #ecefed,
  'gray-30': #e5ebe7,
  'gray-40': #c9d2cc,
  'gray-50': #afb9b2,
  'gray-60': #919d95,
  'gray-70': #66766b,
  'gray-80': #1c2820,
  'gray-90': #020d06,

  //Grey Scale - Dark Mode
  'gray-000': #020d06,
  'gray-100': #171b18,
  'gray-200': #242524,
  'gray-300': #2c2d2c,
  'gray-400': #3a423d,
  'gray-500': #57635b,
  'gray-600': #919d95,
  'gray-700': #c0cec4,
  'gray-800': #e3eee7,
  'gray-900': #f4fcf6,

  //Backgrounds
  'white-00': #fcfffd
);

:root {
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }
}
