.bs {
  border: 1px solid;
}
.bst {
  border-collapse: collapse;
}

.to-export-container {
  position: absolute;
  width: 80%;
  top: 10% !important;
  bottom: 10% !important;
  left: 10% !important;
  display: block;
  border-radius: 10px;
  overflow: auto;

  h1,
  h3,
  h4 {
    font-weight: 600;
  }

  h4 {
    margin-bottom: 0.75rem;
  }
}

.content-to-convert-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2rem;
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.content-to-convert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 755px;
  max-width: 755px;
  gap: 2rem;
  color: black !important;
  background-color: white !important;
  padding: 0 8px;
}

.modal-kyb-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
}

.detail-flex {
  display: flex;
  gap: 0.5rem;
}

.detail-flex-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detail-flex-column-2 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
}

.detail-flex-column-3 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.detail-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.detail-group-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.detail-group-grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}

.detail-group-grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}

.detail-group-grid-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}

.detail-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail-group-flow {
  grid-gap: 5px;
  display: grid;
  grid-template-columns: repeat(6, auto);
  border-radius: 12px;
  border: 1px solid lightblue;
  padding: 1rem;
}

.group-table {
  width: 100%;
}

.table-cell {
  margin: 5px;
  word-wrap: break-word;
}

.col-1 {
  width: 755px;
  max-width: 755px;
  margin: auto;
}

.col-2-small {
  width: 100px;
  max-width: 100px;
}

.col-2-big {
  width: 655px;
  max-width: 655px;
}

.col-3 {
  width: 251px;
  max-width: 251px;
}

.col-4 {
  width: 188px;
  max-width: 188px;
}

.col-5 {
  width: 151px;
  max-width: 151px;
}

.cade-wrapper {
  margin-top: 2rem;
}

.report-pdf-wrapper {
  img {
    width: 100%;
  }
}