.checks-list {
  display: flex;
  flex-direction: row;
  align-items: center;

  CheckList {
    flex: 1;
  }
  p {
    flex: 3;
  }
}
