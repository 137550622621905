.alertFetchSpinner {
  z-index: 1201;
  position: fixed;
  left: 50%;
  top: 90%;
  overflow: hidden;
}
.spinnerFetching {
  padding: 15px 25px;
  display: flex;
  align-items: center;
}

.spinnerFetching-noPadding {
  display: flex;
  align-items: center;
}
