.transaction-filter-content {
  display: grid;
  grid-template-columns: repeat(5, auto);
  align-items: end;
  gap: 1rem;
}

.impesa {
  grid-template-columns: repeat(5, auto) !important;
}

.wrapper2 {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
}
.wrapper3 {
  border-right: 4px solid #00aa18;
}

.test {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}

.text-container {
  display: block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
