.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  
  .footer {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    gap: 1rem;
  }
}