.alert-settings-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.alert-settings-switch {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.alert-settings-service {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.alert-settings-overflow {
  max-height: calc(100vh - 5rem);
  overflow: auto;
}

.flex-alert {
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  gap: 0.5rem;
  margin: auto;
  align-items: center;
  justify-content: center;
}
