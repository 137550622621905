.aml-detail-head {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1.5rem;
}

.aml-detail-box {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.aml-detail-input {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.aml-detail-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.aml-detail-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.aml-br-detail-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.aml-detail-id {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aml-detail-accordion-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}

.aml-br-detail-accordion-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}
