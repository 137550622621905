.role-filter-content {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: end;
  gap: 1rem;
}

.role-creation-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 60vh;
  overflow: auto;
  padding-right: 0.5rem;
  width: 100%;
}

.role-creation-grid {
  display: grid;
  grid-template-columns: 15.5rem auto;
  gap: 1rem;
  overflow: auto;
  height: 100%;
}

.role-creation-back {
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
}

.role-service-wrapper {
  padding: 0.75rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.role-service-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}


.title-roles {
  display: flex;
  justify-content: space-between;
}

.subservices-title {
  padding: 2rem 0rem 1rem 2rem;
}

.role-creation-tab-panel {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.create-role {
  position: absolute;
  width: 35%;
  top: 25%;
  left: 35%;
  background-color: #fff;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 2rem;
  border-radius: 10px;
}

.create-role-content-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  gap: 1rem;
}

.role-creation {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.role-creation-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.role-creation-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.create-role-error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.create-role-error {
  text-transform: none !important;
  font-size: 0.8rem !important;
}

.select-super-role {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.creation-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .head-select {
    width: 100%;
  }
}