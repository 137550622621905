.traffic-light-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;

  .traffic-item {
    display: flex;
    flex-direction: row;
    margin: 10px;

    .title {
      font-size: 1rem;
      font-weight: 600;
    }

    .dot {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
    }
    .red {
      background-color: var(--high);
    }
    .yellow {
      background-color: var(--medium);
    }

    .green {
      background-color: var(--low);
    }
  }
}

.image-slider {
  max-width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .indicators {
    display: flex;
    justify-content: center;
  }

  .indicator {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }

  .indicator.active {
    background-color: #0dde4e;
  }
}

.slider-image {
  width: 100%;
  height: 40vh;
  border-radius: 8px;
  object-fit: contain;
}

.prev-button,
.next-button {
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}

.tags-flex {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
