.rule {
  display: flex;
  max-width: 100%;
  background-color: var(--white);
  border-radius: var(--radius-m);

  .ruleContainer {
    flex: 6;
    max-width: 100%;
  }
}
