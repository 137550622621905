.navbar-wrapper {
  height: 60px;
  width: calc(100% + 2rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0 2.5rem;
  margin-left: -2rem;

  .navbar-right {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
  }

  .title {
    grid-column: 1;
    grid-row: 1;
    font-size: 1.65rem;
    text-transform: uppercase;
    color: var(--grey-70);
  }
}

.navbar-item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.navbar-subitem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navbar-roles {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.avatar {
  display: flex;
  align-items: center;

  .icon {
    font-size: 2.5rem;
    color: var(--info-dark);
  }

  .icon:hover {
    cursor: pointer;
  }
}

.lang-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-right: 0.5rem;
  padding: 0;
}
