.profile-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 50px);
}

.profile {
  width: auto !important;
  margin: 1rem;
  background-color: #fff !important;
  padding: 2rem 3rem;
  border-right: 4px solid #00aa18;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px !important;
}

.profile .data {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.change-password {
  position: absolute;
  width: 35%;
  top: 25%;
  left: 35%;
  background-color: #fff;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 2rem;
  border-radius: 10px;
}

.change-password .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1.5rem;
}
