.checkDetailsContainer {
  padding: 16px !important;
  position: relative;
}

.loadingSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.gridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.MuiOutlinedInputInput {
  padding-right: 0 !important;
}

.sectionsContainer {
  display: grid;
  grid-template-columns: 270px auto;
  gap: 24px;
}

.sectionsSelector {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.sectionInfoContainer {
  padding: 16px 24px !important;
}

.sectionInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
}