.container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .label {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .items {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
}