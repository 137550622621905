.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.govcheck-manual-divider {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  height: 100%;
}

.govcheck-detail-grid-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.govcheck-detail-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.govcheck-detail-accordion-wrapper-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.govcheck-detail-input-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.govcheck-detail-id {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.govcheck-detail-accordion-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.govcheck-manual-input {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.govcheck-detail-input {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
}

.govcheck-detail-string-response {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.75rem 0rem;
}

.govcheck-detail-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
