
.payment-methods-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;

  .payment-methods-tablist {
    &.MuiTabs-root .MuiTabs-flexContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  .MuiChip-root {
    justify-content: flex-start;
    width: 100%;
  }

  .MuiButtonBase-root.MuiTab-root {
    width: 100%;
    align-items: flex-start;
  }

  .payment-methods-details-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
    height: fit-content;

    .payment-methods-info {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 0.5rem;

      .payment-methods-box {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }

      .payment-methods-flex {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }

    .payment-methods-info-bottom {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;

      .payment-methods-info-box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        height: 100%;
        width: 100%;

        .payment-methods-info-button-list {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .payment-methods-box-center {
            width: 100%;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
          }
        }
      }
    }
  }

  .payment-methods-details-bottom {
    display: grid;
    grid-template-columns: 17% calc(83% - 1.5rem);
    gap: 1.5rem;
  }
}

.file-truncated-name {
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 1600px) {
  .file-truncated-name {
    max-width: none;
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
  }
}

.payment-methods-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.users-type-buttons {
  display: flex;
  gap: 32px;
  width: 300px;
}