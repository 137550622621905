.tabs-expandable-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.tabs-expandable-module {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.tabs-expandable-text {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  width: 100%;
}

.tabs-checkbox {
  display: flex;
  flex-direction: column;
}

.tabs-checkbox-item {
  display: flex;
  flex-direction: column;
}

.tabs-checkbox-box {
  display: flex;
  align-items: center;

  label {
    margin: 0;
  }

  span {
    padding: 0.5rem;
  }
}

.tabs-top {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  .tabs-position-1 {
    grid-column: 1;
    margin-right: auto;
  }

  .tabs-position-2 {
    grid-column: 2;
  }

  .tabs-position-3 {
    grid-column: 3;
    margin-left: auto;
  }
}
