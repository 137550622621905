.kyt-manual {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;

  .kyt-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;

    .kyt-form-pair {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .kyt-form-pair-label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .kyt-sub-form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  hr {
    color: gray;
    width: 100%;
  }
}

.kyt-manual-overflow {
  max-height: calc(100vh - 20rem);
  overflow: auto;
  padding-right: 1rem;
}

.kyt-user-search {
  grid-column: 1 / 5;
}

.kyt-user-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr auto auto;
  align-items: end;
  gap: 1rem;
}
