.container {
  display: flex;
  flex-flow: column;
  width: 80vw;
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;

  .rowError {
    background-color: lightpink;
  }

  .rowHit {
    font-weight: bold;
  }
}