html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
}

#root {
  height: 100vh;
}

.home {
  display: grid;
  grid-template-columns: 17.5rem calc(100% - 17.5rem);
  .homeContainer {
    display: flex;
    flex-direction: column;
    padding: var(--size-m);
    margin: auto;
    width: calc(100% - 2rem);

    .widgets {
      display: grid;
      grid-template-columns: 25% 75%;
      grid-template-rows: auto auto;
      padding: var(--size-m);
      gap: var(--size-m);
      gap: 1rem;
      height: 100%;

      .consumptions-w {
        grid-column: 1;
        grid-row: 1;
        display: flex;
      }
    }

    .multiple-charts {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      align-items: center;
      justify-items: center;
      grid-row: 1 / 3;
      grid-column: 2;
    }
  }
}

.title-general {
  font-size: 1.7rem;
  display: flex;
  justify-content: space-between;
}

.title-transactions {
  display: flex;
  justify-content: flex-end;
}

.title-general.margin {
  margin-bottom: 2rem;
}

.title-general.margin-b {
  margin-bottom: 1.2rem;
}

.spinner-transition {
  width: 100%;
  opacity: 0.3;
}

.modal-password-days {
  position: absolute;
  max-width: 30rem;
  width: 45%;
  top: 25%;
  left: 35%;
  background-color: var(--bright-white);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.modal-password-message {
  display: flex;
  gap: 0.4rem;

  span {
    font-weight: 600;
    color: #0ddd4e;
  }
}
