.tag-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr) 3rem;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: .75rem;
}

.tag-pair {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
