.report {
  width: auto !important;
  background-color: #fff !important;
  padding: 2rem 3rem;
  border-right: 4px solid #00aa18;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px !important;

  .title {
    width: 100%;
    font-size: 24px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000000;

    .link {
      text-decoration: none;
      color: #0dde4e;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.report-detail-modal {
  position: absolute;
  width: 90%;
  top: 10% !important;
  bottom: 10% !important;
  left: 5% !important;
  right: 5% !important;
  overflow: auto;
  display: block;
}

.report-detail {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;

  .detail-child {
    max-width: 40%;
    margin-left: 20px;
    margin-right: 20px;
  }
}
