.range-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.range-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.range-label {
  display: flex;
  gap: 0.5rem;
}
