.onboarding-filter-content {
  display: grid;
  grid-template-columns: repeat(6, auto);
  align-items: center;
  gap: 1rem;
}

.check-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.check-title {
  display: 'flex';
  flex-direction: column;
  gap: '1rem';
  justify-content: center;
  align-items: center;
}

.check-render {
  display: flex;
  flex-direction: row;
  .red {
    color: red;
  }
  .grey {
    color: grey;
  }
  p {
    margin-left: 10px;
  }
}

.received-imgs {
  width: 60%;
  border-radius: 10px;
  margin: auto;
}

.error-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img-pair {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.webhook {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.input-error {
  color: red;
}

.manual-processing {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
}

.check-result {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    text-align: center;
  }
}

.checks-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.onb-manual-divider {
  display: grid;
  grid-template-columns: 65% 35%;
  height: 100%;
}

.onb-manual-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.onb-manual-overflow {
  max-height: calc(100vh - 16.75rem);
  overflow: auto;
  padding-right: 1rem;
}

.onb-manual-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.onb-manual-flex {
  display: flex;
  gap: 1rem;
}

.onb-manual-grid {
  display: grid;
  grid-template-columns: 35% 65%;
  align-items: center;
  gap: 1rem;
}

.onb-manual-wrap {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.onb-manual-results-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.onb-manual-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.onb-manual-results {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.onb-manual-sub-results {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.onb-files-drag {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.onb-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .onb-file-description {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .onb-file-icons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
