.container {
  background-color: #f4f8f6;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  min-width: 30rem;
  max-width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem;
  border-radius: 1.5rem;
}

.formContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.inputField {
  margin-right: 16px;
  margin-bottom: 16px;
}

.actionButtons {
  display: flex;
  justify-content: space-evenly;
}