.batch_page_container {
  display: flex;
  gap: 1rem;

  .batch_actions {
    max-width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 4.5rem;

    .create_batch_container {
      display: flex;
      width: 100%;
      padding: 1.25rem;
      margin-bottom: 1.25rem;
      gap: 1rem;
    }

    .update_batch_container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 1.25rem;
      min-height: 150px;
      gap: 1rem;

      .update_batch_header_actions {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        .update_batch_header_title_and_close {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .closeIcon {
            align-self: flex-end;
          }
        }

        .update_batch_header_button_group {
          display: flex;
          gap: 1rem;
          margin: 1rem;
        }
      }
    }

    .update_batch_processed_or_ready_messages {
      height: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .batch_table {
    width: 100%;
  }
}

.file_uploader_container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.batch_details_body {
  margin-bottom: 1.25rem;
}

.batch-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;

  .batch-tablist {
    &.MuiTabs-root .MuiTabs-flexContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  .MuiChip-root {
    justify-content: flex-start;
    width: 100%;
  }

  .MuiButtonBase-root.MuiTab-root {
    width: 100%;
    align-items: flex-start;
  }

  .batch-details-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;

    .batch-info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 0.5rem;

      .batch-id {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .batch-box {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }

      .batch-flex {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }

    .batch-info-bottom {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;

      .batch-info-box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        height: 100%;
        width: 100%;

        .batch-info-button-list {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .batch-box-center {
            width: 100%;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
          }
        }
      }
    }
  }

  .batch-details-bottom {
    display: grid;
    grid-template-columns: 15% calc(85% - 1.5rem);
    gap: 1.5rem;
  }
}

.file-truncated-name {
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 1600px) {
  .file-truncated-name {
    max-width: none;
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
  }
}

.batch-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.modal-title-batch {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
