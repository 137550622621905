.change-password-container {
  height: 100vh;

  .grid {
    margin-top: 32px;
    margin-bottom: 32px;

    .logo {
      width: 48px;
      height: 48;
    }

    .flex {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.change-password-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #eeeeee;
  padding: 1rem;
  margin-bottom: 2rem;
  gap: 0.6rem;

  .title {
    font-size: 1rem;
  }
}

.change-password-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #eeeeee;
  padding: 3rem 1rem;
  gap: 2rem;

  .title {
    text-align: center;
    font-size: 1rem;
    margin: 0rem;
  }

  button {
    margin: 0.5rem !important;
  }
}

.change-password-error {
  margin-bottom: 2rem;
}

.change-password-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
