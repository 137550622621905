.layout {
  display: flex;
  height: 100%;

  .sidebar {
    z-index: 2;
  }

  .view-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex-grow: 1;

    .navbar {
      width: 100%;
      height: fit-content;
    }

    .view {
      margin: auto;
      width: 100%;
      height: 100%;
      padding: 1rem 0.5rem 1rem 1rem;
    }

    .overview {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 100%;
      width: 100%;
      padding-right: 0.5rem;

      .overview-top {
        display: flex;
        gap: 1rem;
        height: fit-content;
        width: 100%;
      }

      .overview-bottom {
        display: flex;
        gap: 1rem;
        width: 100%;
        height: 100%;
      }

      .featured {
        display: flex;
        height: 100%;
      }

      .charts {
        display: flex;
        width: 100%;
      }
    }
  }
}
