.users-admin-filter-content {
  display: grid;
  grid-template-columns: repeat(5, auto);
  align-items: end;
  gap: 1rem;
}

.user-icons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.modal-user {
  position: absolute;
  width: 35%;
  top: 25%;
  left: 35%;
  background-color: #fff;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 2rem;
  border-radius: 10px;
}

.modal-user.edition {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  grid-column: 1 / 4;
}

.modal-user.delete {
  width: 40% !important;
}

.modal-user .form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.tableBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-text-bold {
  font-weight: 500;
  font-size: larger;
}

.user-text-delete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}

.user-text-delete-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.user-width {
  width: 100%;
}

.user-roles {
  margin-top: 1.2rem;
  width: 100%;
}

.user-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.user-admin-chip-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem;
  margin: 0.25rem 0;
}

.user-admin-flex {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
