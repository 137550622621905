.matrix-add-button {
  align-self: end;
}

.matrix-manual-divider {
  display: grid;
  grid-template-columns: 65% 35%;
  height: 100%;
}

.matrix-manual-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.matrix-manual-overflow {
  max-height: calc(100vh - 16.75rem);
  overflow: auto;
  padding-right: 1rem;
}

.matrix-manual-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.matrix-manual-flex {
  display: flex;
  gap: 1rem;
}

.matrix-manual-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.matrix-level-bar {
  display: flex;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
}

.matrix-level-bar-item {
  display: flex;
  align-items: center;
  padding: 0rem 0.25rem;
}

.matrix-manual-results-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.matrix-attribute {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.matrix-filter-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
