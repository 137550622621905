.container {
  display: flex;
  flex-direction: column;
  padding-right: 8px;
}

.fullWidthGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 82% 18%;
}

.paperStyle {
  margin-right: 16px;
  margin-bottom: 16px;
}

.pageTitle {
  margin-bottom: 16px !important;
}

.gridItems {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

.buttonContainer {
  width: 100%;
  margin-bottom: 8px !important;
}

.tabList {
  margin-bottom: 8px;
  border-bottom: 1px solid #1C282026;
}

.tabListScrollbarLight {
  & ::-webkit-scrollbar-thumb {
    background: #c9d2cc;
  }
}

.tabListScrollbarDark {
  & ::-webkit-scrollbar-thumb {
    background: #57635b;
  }
}

.tabListFlexContainer {
  justify-content: center;
}

.tabMaxWidth {
  max-width: 100%;
}

.tabPanelContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.customPaper {
  padding: 16px !important;
}
