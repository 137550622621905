.user-filter-content {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: end;
  gap: 1rem;
}

.accordion {
  margin-bottom: 20px;
}

.flag {
  display: flex;
  justify-content: left;
  align-items: center;

  .flag-child {
    margin-left: 10px;
    display: flex;
  }
}

.ud {
  display: flex;

  .ud-big {
    flex: 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .tl {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      padding: 1rem;
    }
  }

  .ud-small {
    flex: 1;
  }

  .tags {
    margin: 10px;
  }

  .tl {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding: 1rem;
  }

  .ld {
    width: 100%;
  }
}

.pep {
  display: flex;
  align-items: start;
  gap: 0.5rem;
}

.user-admin-id {
  max-width: 8rem;
  text-overflow: ellipsis;
  overflow: hidden;
}