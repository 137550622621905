.comingSonnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;

  img {
    margin-top: 20px;
  }

  h1 {
    margin-top: 20px;
  }

  .divH3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}
