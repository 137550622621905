.aml-manual-divider {
  display: grid;
  grid-template-columns: 65% 35%;
  height: 100%;
}

.aml-manual-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.aml-manual-wrapper-grid {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
}

.aml-manual-overflow {
  max-height: calc(100vh - 16.75rem);
  overflow: auto;
  padding-right: 1rem;
}

.aml-manual-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.aml-manual-flex {
  display: flex;
  gap: 1rem;
}

.aml-manual-grid {
  display: grid;
  grid-template-columns: 35% 65%;
  align-items: center;
  gap: 1rem;
}

.aml-manual-wrap {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.aml-manual-results-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.aml-manual-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.aml-manual-results {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.aml-manual-sub-results {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
