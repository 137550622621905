.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;

  .left,
  .right {
    display: flex;
    flex-direction: column;

    .counter {
      font-size: 20px;
      font-weight: 200;
    }

    .link-widget {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: max-content;
      height: 20px;
      font-size: 12px;
      text-decoration: none;
      border-bottom: 0 none white;
    }

    .link-animation {
      transition:
        font-size 0.75s,
        font-weight 0.75s,
        color 0.75s,
        text-decoration 0.75s;
    }

    .link-animation:hover {
      font-size: 14px;
      font-weight: 800;
      color: #0dde4e;
      text-decoration: underline #0dde4e;
      cursor: pointer;
    }

    .percentage {
      display: flex;
      align-items: center;
      font-size: 14px;

      &.positive {
        color: #0dde4e;
      }
      &.zero {
        color: var(--blue-50);
      }
      &.negative {
        color: #f44336;
      }
    }

    .icon {
      padding: var(--size-sm);
      border-radius: 1rem;
      align-self: flex-end;
      background-color: var(--grey-25) !important;
      box-shadow: 0px 1px 10px rgba(3, 3, 3, 0.12);

      .user-icon {
        fill: var(--blue-50) !important;
      }

      .transaction-icon {
        fill: var(--green-50) !important;
      }

      .consumption-icon {
        fill: var(--orange-40) !important;
      }
    }
  }
}
