.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-width: 30vw;
  min-height: 45vh;
  margin-bottom: 5rem;
}

.login-box {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}
