.workflow-manual-wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1rem;
  height: 100%;
}

.workflow-manual-flex {
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: center;
}