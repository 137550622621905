.low-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  align-items: end;
  gap: 0.5rem;
}

.low-pair {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .low-label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .low-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 10rem;
  }
}
