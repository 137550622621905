.ecommerce-filter-content {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: end;
  gap: 1rem;

  .error-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
