.dnd-flow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: "624px"
}

@media screen and (min-width: 768px) {
  .dnd-flow {
    flex-direction: row;
  }
}
