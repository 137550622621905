.webhook-icons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.webhook-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.webhook-box-id {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.webhook-text-bold {
  font-weight: 500;
  font-size: larger;
}

.webhook-text-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.webhook-text-delete-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.modal-webhook {
  position: absolute;
  width: 35%;
  top: 25%;
  left: 35%;
  background-color: #fff;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

.webhook-width {
  width: 100%;
}

.webhook-filter-content {
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: end;
  gap: 1rem;
}
