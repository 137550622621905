.amount-date-container {
  display: grid;
  gap: 0.9rem;
  grid-template-columns: 1fr 0.3fr;
  margin: 2rem 0;
}

.amount-date {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.amount-date-row {
  display: grid;
  grid-template-columns: 1.5fr repeat(3, 1fr);
  gap: 0.5rem;
  align-items: center;
}

.amount-date-row-span {
  grid-column: 2 / 4;
}

.amount-date-button-right {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.amount-date-selected-ranges {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.amount-date-selected-ranges-container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
}

.amount-date-selected-ranges-row {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.amount-date-flex {
  display: flex;
  gap: 1rem;
  align-items: center;
}
