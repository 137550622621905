.spinner {
  z-index: 1501;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.suspense-spinner-wrapper {
  width: 100%;
  height: 100%;
}

.suspense-spinner {
  z-index: 1501;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-n {
  z-index: 1501;
  position: fixed;
  top: 50%;
  left: 55%;
}

.spinner-filters {
  z-index: 1501;
  position: fixed;
  top: 50%;
  left: 65%;
}
