.color {
  color: #0dde4e !important;
}

.modal-case {
  overflow: scroll;
}

.send-btn {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    align-self: flex-end;
  }
}

.case-detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.case-edit-wrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.case-edit-icon {
  svg {
    height: 1.2rem;
    width: 1.2rem;
  }
}

.case-edit-icon:hover {
  cursor: pointer;
}

.case-workflow-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 25rem;
}

.case-file-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .case-files {
    display: flex;
    gap: 1.75rem;
    overflow-x: auto;

    .case-file {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: flex-start;
      gap: 0.5rem;

      .case-file-icon-wrapper {
        display: flex;
        flex-direction: column;

        .case-close-icon {
          align-self: end;
          margin-bottom: -1rem;

          svg {
            height: 1rem;
            width: 1rem;
          }
        }
      }

      .case-file-name {
        font-size: 0.8rem;
        max-width: 10ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0.5rem;
      }

      .case-file-icon {
        button {
          padding: 0;
        }

        svg {
          height: 3.7rem;
          width: 3.7rem;
        }
      }
    }
  }
}

.case-admin-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.case-admin-status {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.case-detail-accordions-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.case-admin-panel-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.case-subdetail {
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 0rem 1rem;
}

.case-timeline-comments {
  overflow-x: scroll;
  overflow-y: scroll;
  grid-column: 1;
  display: flex;
  flex-direction: column;
}

.case-timeline-comments::-webkit-scrollbar {
  width: 0px;
}

.case-timeline-comments::-webkit-scrollbar-thumb {
  background: transparent;
}

.ql-editor {
  direction: ltr;
}

.report-header {
  margin-bottom: 16px;
}

.status-icon {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: flex-start;
  padding: 0.2rem 0.6rem;
  border-radius: 6.25rem;
  width: fit-content;

  p {
    font-weight: 600; 
    font-size: 0.7rem; 
    line-height: 1.1rem;
    text-transform: capitalize;
  }
  
  .st-icon {
    height: 0.4rem;
    width: 0.4rem;
  }
}