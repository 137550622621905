.my-rules-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drawerContainer {
  width: 40vw;
}

.drowerButtonPanel {
  display: flex;
  justify-content: space-between;

  .buttonDrawer {
    margin: 20px;
  }
}

.myRulesPair {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  margin-left: 20px;
  width: 70%;
  margin-bottom: 15px;
}

.minLabel {
  font-weight: bold;
  margin-right: 10px;
}

.myRulesMinPair {
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  width: 70%;
  margin-bottom: 15px;
}

.parametersLabel {
  color: gray;
}

.my-rules-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.my-rules-subtitle {
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
