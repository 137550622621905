.device {
  width: 100%;
  margin-top: 10px;
}

.workflows-2 {
  width: 100%;
  margin-top: 16px;
}

.img-wrapper {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  margin-bottom: 16px;

  img {
    width: 100%;
    margin: 10px;
  }
}

.device-table {
  cursor: pointer;
}

.img-separator {
  margin-bottom: 16px;
  border-radius: 10px;
}
