.rjson-wrapper {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 0.65rem;

  .opening {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
    font-weight: 600;

    .arrow {
      width: 1rem;
      height: 1rem;
    }
  }

  .opening:hover {
    cursor: pointer;
  }
}

.recursive-wrapper {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  .recursive-pair {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    justify-content: start;

    p {
      word-wrap: break-word;
    }
  }
}
