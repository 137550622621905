//BORDER RADIUS
$radius: (
  'radius-8': 8px,
  'radius-16': 16px,
  'radius-24': 24px,
  'radius-32': 32px,
);

:root {
  @each $rad, $value in $radius {
    --#{$rad}: #{$value};
  }
}

//SIZES
$sizes: (
  'size-xs': 0.25rem,
  'size-sm': 0.5rem,
  'size-ms': 0.75rem,
  'size-m': 1rem,
  'size-md': 1.5rem,
  'size-lg': 3rem,
  'size-xl': 6rem,
);

:root {
  @each $size, $value in $sizes {
    --#{$size}: #{$value};
  }
}

//WEIGHTS
$weights: (
  'light-weight': 200,
  'medium-weight': 300,
  'regular-weight': 400,
  'light-bold-weight': 500,
  'bold-weight': 600,
);

:root {
  @each $weight, $value in $weights {
    --#{$weight}: #{$value};
  }
}
